import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout'
import { Maintenance as MaintenanceComponent } from '../components/pages'

const Maintenance: React.FC = ({ data, location }) => {
  return (
    <Layout pageName="Maintenance" header={false} location={location}>
      <MaintenanceComponent image={data.file.childImageSharp.gatsbyImageData} />
    </Layout>
  )
}

export default Maintenance

export const query = graphql`
  query MaintenanceQuery {
    file(relativePath: { eq: "maintenance.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, height: 800)
      }
    }
  }
`
